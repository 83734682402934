import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { Menu } from "@mui/material";
import { getUserDetails, logout } from "../../redux/actions/auth-actions";
import moment from "moment";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";

export default function Header(props) {
  const dispatch = useDispatch();
  const [count, setcount] = useState("");
  const [notificationId, setNotificationId] = useState("");
  const [isOpenCompany, setIsOpenCompany] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  let navigate = useNavigate();
  // const [searchfeedback, setSearchfeedback] = useState("");
  const [searchTure, setSearchTure] = useState(false);
  const [dataSearchAdd, setDataSerachAdd] = useState("");
  const [isOpenCompanyDropdown, setIsOpenCompanyDropdown] = useState(true);
  const [showCompanyDropdown, setShowCompanyDropdown] = useState(false);
  const [profile_img, setProfileImage] = useState([]);
  const [companyName, setCompanyName] = useState();
  const [anchorElInProgress, setAnchorElInProgress] = useState(null);
  const openMenuInProgress = Boolean(anchorElInProgress);
  const [oldcount, setOldcount] = useState("");
  const [newcount, setNewcount] = useState("");
  const [first, setFirst] = useState(false);
  const menuRef = useRef(null);
  const companyRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClickNotifications = (event) => {
    setAnchorEl(event.currentTarget);
    const formData = new FormData();
    if (props.headerCompany) {
      formData.append("company_id", props.headerCompany);
    } else {
      formData.append("company_id", "");
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { userData } = useSelector((state) => state.authReducer);

  const { user, successDetails } = useSelector(
    (state) => state.userDetailsReducer
  );

  const [isActive, setIsActive] = useState(false);


  useEffect(() => {
    const handler = () => {
      setIsOpenCompany(false);
    };
  }, []);

  const [rowadd, setrowadd] = useState([]);



  const chatSocket = new WebSocket(
    "wss://" +
      "dev-ws.adifect.com" +
      "/ws/notifications/" +
      userData?.user?.user_id +
      "/"
  );

  chatSocket.onmessage = function (e) {
    const data = JSON.parse(e.data);

    if (data && data?.data?.value) {
      let newStr1 = data.data.value.text.count;
      setcount(newStr1);
    }
  };

  chatSocket.onclose = function (e) {};








  const menuProps = {
    variant: "menu",
  };

  const toggleClass = (e) => {
    if (
      menuRef.current &&
      showDropdown &&
      !menuRef.current.contains(e.target)
    ) {
      setShowDropdown(false);
    }
  };
  document.addEventListener("mousedown", toggleClass);

  const toggleCompanyClass = (e) => {
    if (
      companyRef.current &&
      showCompanyDropdown &&
      !companyRef.current.contains(e.target)
    ) {
      setTimeout(function () {
        setShowCompanyDropdown(false);
      }, 1200);
    }
  };
  document.addEventListener("mousedown", toggleCompanyClass);

  const logoutHandler = () => {
    props.setHeaderCompany(null);
    dispatch(logout());
  };









  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest(".agency-jobssearch")) return;
      setSearchTure(false);
    };

    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);




  // ---------------------------keydown function for seach function--------------------

  return (
    <>
      <div className="mainDiv">
        <div className="header">
          <div className="logo">
            
            <h1>SaaSot</h1>
          </div>

          
          <div className="loginRight">
           
            <li
              className="ml-7 johndoe"
              ref={menuRef}
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <Link className="LoginName dropdown" to="#">
                <span className="header-profile-pic">
                  {user?.profile_img && (
                    <img src={user?.profile_img} alt="Profile Picture" />
                  )}
                  {!user?.profile_img && (
                    <img
                      src={process.env.PUBLIC_URL + "/img/avataruser.png"}
                      alt=""
                    />
                  )}
                </span>
                <span className="loginName ml-1">
                  {userData?.user?.first_name && userData?.user?.last_name ? (
                    <>
                      {userData?.user?.first_name} {userData?.user?.last_name}
                    </>
                  ) : (
                    <>{userData?.user?.name}</>
                  )}
                </span>
                <i className="fa fa-caret-down dropdown"></i>
              </Link>
              {showDropdown && (
                <>
                  <div className="loginsigin">
                    <li onClick={logoutHandler}>
                      <Link to="/">
                        <img
                          className="mr-2 logout"
                          src={process.env.PUBLIC_URL + "/img/logout.png"}
                          alt=""
                        />
                        Logout
                      </Link>
                    </li>
                  </div>
                </>
              )}
            </li>
          </div>
        </div>
      </div>
    </>
  );
}
