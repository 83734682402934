import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { useSelector } from "react-redux";
import { ROLE } from "./../../constants/other-constants";
import { useNavigate, useLocation } from "react-router-dom";

export default function Sidebar() {
  let navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  // const splitLocation = pathname.split("/");
  function closeNav() {
    document.getElementById("mySidepanel").style.width = "0";
  }
  const { userData } = useSelector((state) => state.authReducer);

  const [isTextVisible, setIsTextVisible] = useState(true);

  const toggleTextVisibility = () => {
    setIsTextVisible(!isTextVisible);
  };

  useEffect(() => {
    if (!userData) {
      navigate("/");
    }
  }, []);

  let topData;
  let midData;
  let bottomData;
  // if (userData?.user?.role == Object.keys(ROLE)[0]) {
    //Admin Sidebar.
    topData = [
      { title: "Home", imgPath: "/img/homeicon.png", path: "/home" },
      {
        title: "users",
        imgPath: "/img/user1.png",
        path: "/users",
      },
      // {
      //   title: "Media",
      //   imgPath: "/img/Earnings_old.png",
      //   path: "/admin-media",
      // },
     
    ];
    midData = [
      { title: "upload-file", imgPath: "/img/jobicon1.png", path: "/upload-file" },
      {
        title: "CustomerUpload",
        imgPath: "/img/icon40.png",
        path: "/customer-upload",
      },
      
      {
        title: "ShowProductServices",
        imgPath: "/img/skillicon.png",
        path: "/show-products/servies",
      },
      {
        title: "ContractUpload",
        imgPath: "/img/Company-Vector.png",
        path: "/contract-upload",
      },
      {
        title: "AddContract",
        imgPath: "/img/app1.png",
        path: "/add-contract",
      },
    ];
    bottomData = [
      { title: "ClosedPeriod", imgPath: "/img/help.png", path: "/closed-period" },
      {
        title: "QuickbookSetting",
        imgPath: "/img/app1.png",
        path: "/quickbook-connect",
      },
    ];
  // } 
  useEffect(() => {}, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // useEffect(() => {
  //   const handleWindowResize = () => {
  //     setWindowWidth(window.innerWidth);
  //     if (window.innerWidth < 768) {
  //       document.getElementById("mySidepanel").style.width = "0px";
  //     } else {
  //       // document.getElementById("mySidepanel").style.width = "250px";
  //     }
  //   };
  //   window.addEventListener("resize", handleWindowResize);

  //   if (window.innerWidth < 768) {
  //     document.getElementById("mySidepanel").style.width = "0px";
  //   }
  //   if (window.innerWidth > 767) {
  //     document.getElementById("mySidepanel").style.width = "100%";
  //   }
  //   return () => {
  //     window.removeEventListener("resize", handleWindowResize);
  //   };
  // });

  return (
    <>
    <div className="togglenewbtn" onClick={toggleTextVisibility}><img src="/img/togglenew.png"/></div>
    {isTextVisible && (
      <div
      id="mySidepanel"
      className={
        windowWidth < 768
          ? "sidemenubar sidepanel mobile-device-active"
          : "sidemenubar sidepanel"
      }
      style={{ width: "0%" }}
    >
      <ul className="nav-list">
        <h1
          className="closebtn"
          onClick={closeNav}
        >
          ×
        </h1>
        {topData?.map((item, index) => (
          <li className={pathname == item.path ? "active" : ""} key={index}>

            <Link className="Menu" to={item.path}>
              <span className="menu_img">
                <img
                  className="mr-2"
                  src={process.env.PUBLIC_URL + item.imgPath}
                  alt=""
                />
              </span>
              {/* {item.title} */}
            </Link>
          </li>
        ))}

        {/* {midData?.length > 0 && (
          <div className="borderlinesidebar">
            <hr className="borderline" />
          </div>
        )} */}

        {midData?.map((item, index) => (
          <li className={pathname == item.path ? "active" : ""} key={index}>
            <Link className="Menu" to={item.path}>
              <span className="menu_img">
                <img
                  className=""
                  src={process.env.PUBLIC_URL + item.imgPath}
                  alt=""
                />
              </span>
              {/* {item.title} */}
            </Link>
          </li>
        ))}

        {/* {bottomData?.length > 0 && (
          <div className="borderlinesidebar">
            <hr className="borderline" />
          </div>
        )} */}
        {bottomData?.map((item, index) => (
          <li className={pathname == item.path ? "active" : ""} key={index}>

            <Link className="Menu" to={item.path}>
              <span className="menu_img">
                <img
                  className="mr-2"
                  src={process.env.PUBLIC_URL + item.imgPath}
                  alt=""
                />
              </span>
              {/* {item.title} */}
            </Link>
          </li>
        ))}
      </ul>
    </div>
    )}

    
    </>
  );
}
