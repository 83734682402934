import React from "react";
import "./SpinnerLoader.css";

export default function SpinnerLoading() {
  return (
    <>
    <div className="loaderSaaSot">
   <div class="loaderloader"></div>
   </div>
    </>
  );
}